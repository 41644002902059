/** @format */
@import "../../../../Style/common.scss";
.orders-cont {
  background-color: #f7f8fa;
  // height: 100vh;

  &__body {
    box-shadow: 0px 3px 16px #0a4b6d14;
    border: 1px solid #a5c9ff;
    border-radius: 7px;
    margin: 20px 10px;

    background-color: #ffffff;

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 24px;
      &__filter {
        @include fontStyle(14px, 600, #156ab3);
        @include centerText();
        @media only screen and (min-width: 1060px) {
          display: none;
        }
      }
      h4 {
        @include fontStyle(16px, 700, #156ab3);
      }
      button {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 127px;
        height: 32px;
        border: 1px solid #156ab3;
        border-radius: 7px;
        @include fontStyle(14px, 600, #156ab3);
        &:hover {
          color: #ffffff !important;
          background-image: linear-gradient(#249db3, #30c0b4);
          border: none;
        }
      }
      &__types {
        display: flex;

        &__type {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: start;
          margin-left: 14px;
          line-height: 1.3;
          &__dot {
            margin: 0px 6px;
            width: 14px;
            height: 14px;
            border-radius: 11px;
            &--yellow {
              background-color: #ffefca;
            }
            &--red {
              background-color: #ffd9d1;
            }
            &--undefined {
              background-color: #ffd9d1;
            }
            &--green {
              background-color: #aaffbe;
            }
            &--blue {
              background-color: #d1eefe;
            }
          }
          span {
            @include fontStyle(12px, 400);
            display: block;
            &:first-child {
              @include fontStyle(18px, 700);
            }
          }
        }
        &--responsiveView {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          border-radius: 0px 0px 7px 7px;
          background-color: #f5f5f5;
          border-top: 1px solid #a5c9ff;
          padding: 4px;
          @media only screen and (min-width: 1060px) {
            display: none;
          }
        }
        &--normalView {
          @media only screen and (max-width: 1060px) {
            display: none;
          }
        }
      }
    }
  }
}
