/** @format */

@import "../../Style/common.scss";

.bundle-card {
  width: 323px;
  height: 274px;
  border: 2px solid #e2e3e4;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  &__title {
    height: 57px;
    background-color: #156ab3;
    @include fontStyle(20px, 700, #ffffff);
    margin: 0;
    border-radius: 5px 5px 0px 0px;
    @include centerText();
    margin-bottom: 17px;
    &--selected {
      background-color: #7d7d7d;
    }
  }
  &__info {
    display: block;
    @include fontStyle(16px, 400, #112233);
  }
  &__price {
    @include fontStyle(30px, 700, #112233);
  }
  &__btn {
    position: absolute;
    bottom: 26.5px;
    left: 88.25px;
    width: 148px;
    height: 40px;
    border: 1px solid #1e8ab2;
    border-radius: 5px;
    cursor: pointer;
    @include fontStyle(18px, 500, #156ab3);
    @include centerText();
    img {
      margin: 0px 3px;
    }
    &--selected {
      background-color: #e2e3e4;
      @include fontStyle(16px, 500, #7d7d7d);
      border: none !important;
    }
  }
}
