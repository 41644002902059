/** @format */
@import "../../../Style/common.scss";
.customer-form {
  margin: auto;
  max-width: 656px;
  text-align: start;
  &__userFields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      text-align: start;
      width: 323px;
    }
    button {
      margin-top: 20px;
      width: 323px;
      height: 36px;
      border-radius: 5px;
      background-image: linear-gradient(#249db3, #30c0b4);
      @include fontStyle(18px, 600, #ffffff);
      &:disabled {
        background-color: #dddddd;
        color: #7f7f7f;
        background-image: none;
        cursor: not-allowed;
      }
      &:nth-child(2) {
        background-image: none;
        cursor: auto;
      }
    }
    &__cancelBtn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include fontStyle(16px, 400, #e30000);
      cursor: pointer !important;
      color: #e30000 !important;
      font-weight: 400 !important;
      img {
        margin: 0px 5px;
      }
    }
  }
  &__emailFields {
    display: flex;
    justify-content: center;
    div {
      text-align: start;
      width: 100%;
    }
  }
  &__checkBox-cont {
    margin-bottom: 26px;
    &__title {
      display: flex;
      align-items: center;
      span {
        white-space: nowrap;
        margin-left: 10px;
        @include fontStyle(14, 700, #7d7d7d);
      }
      hr {
        color: #7d7d7d;
        width: 100%;
        border-width: 1px 0px 0px 0px;
      }
    }
    &__checkBox {
      display: flex;
      flex-wrap: wrap;
      &__field {
        width: 30%;
        line-height: 1;
        display: flex;
        align-items: center;
      }
    }
    &__permissions {
      display: flex;

      &__header {
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
        text-align: center;
        span {
          width: 42px;
          @include fontStyle(12px, 700, #156ab3);
        }
      }
      &__permission {
        margin-left: 10px;
        width: 330px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
