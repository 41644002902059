/** @format */
@import "../../../Style/common.scss";
.select-cont {
  border: 1px solid #1e8ab2;
  border-radius: 5px;
  padding: 9px 13px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  text-align: start;
  display: flex;
  align-items: center;
  span {
    color: #112233;
    font-weight: 700;
    font-size: 14px;
    height: 30px;
    display: flex;
    align-items: center;
  }
  img {
    position: absolute;
  }
  &__arrow-icon {
    &--en {
      right: 13px;
    }
    &--ar {
      left: 13px;
    }
  }
  &--error {
    border: 1px solid #d00512;
  }
  &--orderCustomStyle {
    border: none;
    padding: 0px 9px;
    height: 32px;
    margin: 0px 5px;
    background-color: #2ebcb4;

    z-index: 6;
    & > span {
      @include fontStyle(12, 600, #ffffff);
    }
  }
  &--disabled {
    background-color: #e2e3e4;
    span {
      color: rgb(84, 84, 84);
      font-weight: 500;
    }
  }
  &__list {
    z-index: 5;
    position: absolute;
    border: 1px solid #1e8ab2;
    border-radius: 5px;
    top: 38px;
    right: -1px;
    width: 100% !important;
    max-height: 200px;
    overflow-y: auto;
    background-color: white !important;
    span {
      display: block;
      cursor: pointer;
      padding: 0px 13px;
      &:hover {
        background-color: #1e8ab2;
        color: #ffffff;
      }
      &:last-child {
        border-radius: 0px 0px 4px 4px;
      }
    }
    &--orderCustomStyle {
      border-color: #2ebcb4;
      top: 27px;
      background-color: transparent !important;
      padding-top: 6px;

      span {
        background-color: #ffffff;
        &:hover {
          background-color: #2ebcb4 !important;
          color: #ffffff;
        }
      }
    }
    &__selectedOption {
      background-color: #1e8ab2;
      color: #ffffff !important;
      &--orderCustomStyle {
        background-color: #2ebcb4 !important;
        color: #ffffff !important;
      }
    }
  }
}
