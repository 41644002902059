/** @format */

.loader-cont {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(234, 240, 246, 0.8);
  top: 0;
  left: 0;
  z-index: 30;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__spinnerAndParantImg {
    position: fixed;
    display: flex;

    justify-content: center;
    z-index: 30;

    &__spinner {
      position: absolute;
      left: 49px;
      bottom: 81px;
      animation: rotation 2s infinite linear;
      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }
    }
  }
}
