/** @format */
@import "../../../Style/common.scss";
@mixin downloadFile() {
  width: 212px;
  height: 130px;
  border: 2px solid #1e8ab2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 58px;
    height: 47px;
  }
  span {
    @include fontStyle(18, 600, #156ab3);
  }
}
.order-cont {
  margin-right: 93px;
  &--apply-margin {
    margin-right: 250px;
  }
  &__info-cont {
    box-shadow: 0px 3px 16px #0a4b6d14;
    border: 1px solid #a5c9ff;
    border-radius: 7px;
    box-sizing: border-box;
    margin: 20px 10px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #a5c9ff;
      padding: 12px 24px;
      h4 {
        margin: 0;
        @include fontStyle(16, 700, #156ab3);
        span {
          @include fontStyle(16, 600, #112233);
        }
      }
      &__status-cont {
        @media only screen and (max-width: 670px) {
          display: none;
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        &__orderStatus {
          @include fontStyle(14, 600, #112233);
          margin: 5px;
        }
        div {
          width: 15%;
          min-width: 212px;
          box-sizing: border-box;
        }
        & > button {
          width: 200px;
          height: 32px;
          background-color: #f16d78;
          border-radius: 7px;
          padding: 4px;
          @include fontStyle(12, 600, #ffffff);
        }
      }
    }
    &__res-status-cont {
      background-color: #f5f5f5;
      height: 45px;
      @include centerText();
      border-bottom: 1px solid #a5c9ff;
      @media only screen and (min-width: 670px) {
        display: none;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    margin: 20px 10px;
    align-items: flex-start;
    button {
      width: 323px;
      height: 40px;
      border-radius: 5px;
      background-image: linear-gradient(#249db3, #30c0b4);
      @include fontStyle(18, 600, #ffffff);
      &:disabled {
        background-color: #dddddd;
        color: #7f7f7f;
        background-image: none;
        cursor: not-allowed;
      }
    }
    &__downloadِAndUploadFile {
      display: flex;
      &__downloadFile {
        margin-left: 20px;
        @include downloadFile();
        &--afterFile {
          border: none;
          width: 323px;
          height: 130px;
          background-image: linear-gradient(#249db3, #30c0b4);
          span {
            @include fontStyle(18px, 700, #ffffff);
          }
        }
      }
      &__uploadFile {
        height: 130px;
        width: 323px;
      }
    }
    &__downloadFile {
      @include downloadFile();
    }
  }
}

.orders-cont {
  height: 80vh;
  overflow: scroll;
}
