/** @format */

.partners-cont {
  margin: 10px 155px;
  margin-bottom: 50px;
  h3 {
    font-size: 30px;
    font-family: 700;
    color: #278db4;
  }
  &__img-cont {
    width: 257px;
    height: 113px;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    display: flex;
    img {
      margin: auto;
      width: 142px;
      height: 70px;
    }
  }
  @media only screen and (max-width: 680px) {
    margin: 10px 10%;
  }
}
.arrow {
  margin-right: 25px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
