/** @format */
@import "../../Style/common.scss";
.page-cont {
  margin-top: 128px;
  margin-bottom: 184px;
  h3 {
    @include fontStyle(26px, 700, #142535);
    margin: 0;
  }
  span {
    @include fontStyle(17px, 400, #142535);
    display: block;
  }
  button {
    background-image: linear-gradient(#249db3, #30c0b4);
    height: 36px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    font-size: 18px;
    font-weight: 600;
    width: 346px;
    margin-top: 11px;
    cursor: pointer;
  }
  img {
    width: 188px;
    height: 117px;
  }
}
