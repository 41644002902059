/** @format */
@import "../../../Style/common.scss";
.sidebar-cont {
  position: fixed;
  width: 240px;
  height: calc(100vh - 39px);
  right: 0;
  top: 0px;
  border-left: 1px solid #e2e3e4;
  // overflow-y: scroll;
  overflow-x: inherit;
  &--closed {
    width: 83px;
  }
  &--left {
    left: 0;
    border-right: 1px solid #e2e3e4;
  }
  &__burger-menu {
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0px 26px 0px 15px;
    &--closed {
      justify-content: center;
    }
    &__menu {
      @include imgStyle(20px, 13px);
    }
    &__logo {
      margin-top: 5px;
      @include imgStyle(102px, 50px);
    }
  }
  &__menu-items {
    padding: 5px 26px 5px 15px;
    &__link {
      display: flex;
      justify-content: space-between;

      border: 0.5px solid #e2e3e4;
      border-radius: 5px;
      padding: 10px 9px;
      text-decoration: none;
      margin-top: 10px;
      background-color: #ffffff;

      span {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include fontStyle(14px, 600, #112233);
      }
      &--selected {
        box-shadow: 5px 0px 0px #156ab3;
        border: 0.5px solid #156ab3;
        span {
          @include fontStyle(14px, 600, #156ab3);
        }
        &:hover {
          background-color: #ffffff !important;
        }
      }

      &__icon {
        @include imgStyle(20px, 23px);
        object-fit: contain;
        // margin-left: 7px;
      }
      &:hover {
        background-color: #ececec;
      }
      @media only screen and (max-width: 950px) {
        border: none;
        &--selected {
          box-shadow: none;
          border: none;
          span {
            @include fontStyle(14px, 600, #156ab3);
          }
          &:hover {
            background-color: #ffffff !important;
          }
        }
      }
    }
    &__dropdown {
      border: 0.5px solid #156ab3;
      border-radius: 0 0 5px 5px;
      margin-top: -6px;
      padding: 6px 6px 10px 6px;
      border-top: none;
      a {
        display: flex;
        @include fontStyle(14px, 600, #112233);
      }
      img {
        margin: 0 10px;
        margin-top: 4px;
      }
      span {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        img {
          margin: 0 10px;
          margin-top: 4px;
        }
      }
    }
    @media only screen and (max-width: 950px) {
      border-bottom: 1px solid #e2e3e4;
    }
  }
  @media only screen and (max-width: 950px) {
    display: none;
    margin: 0;
  }
}
.mobile-sideMenu {
  width: 309px;

  // height: calc(100vh);
  // box-shadow: -12px 0px 6px #00000029;
  &__userInfo {
    @include centerText();
    flex-direction: column;
    height: 154px;
    background-color: #f5f5f5;
    padding-bottom: 25px;
    &__nameAndBadge {
      margin: 24px 0px;

      @include centerText();
      span {
        @include fontStyle(16px, 500, #112233);
      }
      &__badge {
        margin: 0px 10px;
        width: 52px;
        height: 52px;
        border-radius: 100%;
        @include centerText();
        background-image: linear-gradient(#156ab3, #94ded7);
        h1 {
          margin: 0;
          margin-top: -7px;
          @include fontStyle(32px, 700, #ffffff);
        }
      }
    }
    &__reminder {
      display: flex;
    }
    // span {
    //   @include fontStyle(14px, 600, #112233);
    // }
  }
  @media only screen and (min-width: 950px) {
    display: none;
  }
}
.MuiBackdrop-root {
  // margin-top: 65px;
  @media only screen and (min-width: 950px) {
    display: none;
  }
}
.Drawer-burger-menu {
  position: absolute;
  top: 23px;
  right: 18px;
  width: 27px;
  height: 18px;
  cursor: pointer;
  z-index: 1201;
  @media only screen and (min-width: 950px) {
    display: none;
    visibility: hidden;
  }
}
MuiModal-root {
  @media only screen and (min-width: 950px) {
    display: none;
    visibility: hidden;
  }
}
.MuiDrawer-paper {
  margin-top: 65px;
  @media only screen and (min-width: 950px) {
    display: none;
    visibility: hidden;
  }
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(156 152 152 / 50%) !important;
}
.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  box-shadow: none !important;
}

.sidebar-cont.false.sidebar-cont--left {
  overflow-y: auto;
}
