/** @format */

.first-phase {
  &__upload-files {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &__section-cont {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 40%;
      height: fit-content;

      &__title {
        text-align: right;
        font-size: 16px;
        color: #050505;
        font-weight: 600;
      }
      &__dropzone {
        cursor: pointer;
        width: 100%;
        height: 175px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%231E8AB2FF' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 5px;
        padding: 40px;
        box-sizing: border-box;

        img {
          display: block;
          margin: auto;
          width: 59px;
          height: 48px;
        }
        span {
          font-size: 18px;
          color: #050505;
          font-weight: 600;
        }
        &__choose-file {
          text-decoration: underline;
          color: #156ab3 !important;
        }
      }
      &__file-cont {
        margin-top: 35px;
        margin-bottom: 40px;
        display: flex;
        align-items: flex-start;
        width: 100%;

        &__file {
          margin-top: 5px;
          width: 100%;
          max-width: 702px;
          border: 1px solid #d3d3d3;
          border-radius: 5px;
          background-color: #f0f0f0;
          display: flex;
          justify-content: space-between;
          padding: 0px 10px;
          flex-direction: row-reverse;
          align-items: center;
          img {
            cursor: pointer;
          }
          &__fileName {
            font-size: 16px;
            font-weight: 400;
            img {
              margin-bottom: -5px;
            }
          }
        }
      }

      &__textArea-cont {
        width: 100%;
        textarea {
          // margin-top: 8px;
          width: 100%;
          height: 175px;
          resize: none;
          border: 1px solid #1e8ab2;
          border-radius: 5px;
          outline: none;
          font-size: 14px;
          padding: 12px;
          box-sizing: border-box;
        }
      }
    }
    &__seperator {
      width: 75px;
      height: 75px;
      margin: 0 40px;
      margin-top: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 12px solid #249db3;
      font-size: 17px;
      font-weight: 600;
    }
    @media only screen and (max-width: 950px) {
      flex-direction: column;
      align-items: center;
      &__section-cont {
        width: 90%;
      }
      &__seperator {
        margin: 0 40px;
        // margin-top: 20px;
      }
    }
  }
  &__footer-cont {
    margin-top: 35px;
    margin-bottom: 40px;

    text-align: right;
    button {
      margin-top: 10px;
      width: 346px;
      height: 36px;
      color: #ffffff;
      border-radius: 5px;
      background-image: linear-gradient(#249db3, #30c0b4);
      font-size: 18px;
      font-weight: 600;
      &:disabled {
        background-color: #dddddd;
        color: #7f7f7f;
        background-image: none;
        cursor: not-allowed;
      }
      // float: right;
    }
    @media only screen and (max-width: 950px) {
      margin-right: 35px;
    }
  }
}

.opacity-disabled {
  opacity: 0.5;
  pointer-events: none;
}

progress {
  accent-color: #30c0b4;
}
