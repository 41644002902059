/** @format */
@import "../../../Style/common.scss";
.logout-cont {
  position: absolute;
  top: 64px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffffff;
  border: 1px solid #e2e3e4;
  border-radius: 0px 0px 5px 5px;
  text-align: right;
  left: 10px;
  z-index: 9;
  display: none;
  &--show {
    display: block;
  }
  span {
    cursor: pointer;
    height: 30px;
    padding: 0px 12px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &:hover {
      background-color: #ececec;
    }
    img {
      @include imgStyle(15px, 16px);
      margin-left: 9px;
    }
  }
}
.customNav-cont[dir="rtl"] {
  .logout-cont {
    left: unset;
    right: 10px !important;
  }
}
