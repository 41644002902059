/** @format */

.success-modal {
  padding: 33px 75px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 26px #0000004b;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  background-color: white !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  outline: none;
  text-align: center;
  span {
    margin: 10px 0px;
    font-size: 20px;
    color: #112233;
  }
  button {
    background-image: linear-gradient(#249db3, #30c0b4);
    height: 36px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    font-size: 18px;
    font-weight: 600;
    width: 50%;
    margin: auto;
    margin-top: 11px;
    cursor: pointer;
  }
  &__success-modal {
    width: 107px;
    height: 123px;
    margin: auto;
  }
}
.closeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  width: 27px;
  height: 27px;
}
