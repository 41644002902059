/** @format */
@import "../../../Style/common.scss";
.customer-header {
  display: flex;
  margin: 45px 10px;
  justify-content: space-between;
  align-items: center;
  margin: 50px 10px;
  border-bottom: 1px solid #e2e3e4;
  h4 {
    margin: 0;
    @include fontStyle(16px, 700, #156ab3);
  }
  &__buttons {
    button {
      width: 212px;
      height: 46px;
      border-radius: 5px 5px 0px 0px;
      background-color: #e2e3e4;
      @include fontStyle(14px, 400, #7d7d7d);
      //   &:first-child {
      margin-right: 10px;
      //   }
    }
    &__active {
      background-image: linear-gradient(#249db3, #30c0b4);
      color: #ffffff !important;
    }
  }
}
