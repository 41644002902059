/** @format */
@import "../../../Style/common.scss";
.request-search {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  height: 48px;
  padding: 8px 32px;
  border-top: 1px solid #a5c9ff;
  box-sizing: border-box;

  & > div {
    width: 15%;
    min-width: 179px;
    height: 32px;
    border: 1px solid #e2e3e4;
    border-radius: 7px;
    padding: 0px 9px;
    box-sizing: border-box;
    margin: 0px 5px;
    background-color: #ffffff;
    span {
      @include fontStyle(12px, 500);
    }
    input {
      padding: 0;
      background-color: #ffffff;
    }
  }
  &__clear {
    text-align: start;
    border: none !important;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      margin: 0px 5px;
    }
    span {
      color: #7d7d7d !important;
    }
    button {
      background-image: linear-gradient(#249db3, #30c0b4);
      height: 20px;
      border-radius: 5px;
      padding: 10px;
      border: none;
      outline: none;
      color: white !important;
      @include fontStyle(12px, 500);
      width: fit-content;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0px 10px;
    }
  }
  @media only screen and (max-width: 1200px) {
    border-radius: 0px 0px 7px 7px;
  }
  @media only screen and (max-width: 1060px) {
    display: none;
  }
}
.responsive-search-modal {
  .MuiBackdrop-root {
    margin-top: 0 !important;
  }
  &__container {
    position: absolute;
    bottom: 40%;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 7px 7px 0px 0px;
    width: 350px;
    height: 300px;
    background-color: white;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
