/** @format */

.bill-cont {
  text-align: start;
  width: 300px;
  margin-inline-start: 15px;
  h3 {
    font-size: 20px;
    font-weight: 700;
    color: #142535;
    text-align: start !important;
    margin: 0;
  }
  &__body {
    div {
      padding: 0px 5px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 16px;
        color: #112233;
        font-weight: 400;
        &:nth-child(2) {
          font-weight: 700;
        }
      }
    }
    &__extraStyle {
      background-color: #dddddd;
      &__total {
        font-weight: 700 !important;
      }
      &:last-child {
        padding: 4px 5px;
      }
    }
  }
  &__info {
    color: #6c6c6c;
    font-size: 14px;
    img {
      margin-bottom: -2px;
    }
  }
}

.btn.btn-danger {
  color: #fff;
  background-color: #dc3545 !important;
  border-color: #dc3545;
  background-image: none !important;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mt-4 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
