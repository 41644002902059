/** @format */

.notificationPageCont {
  width: 80%;
  max-width: 656px;
  border: 1px solid #a5c9ff;
  border-radius: 7px;

  margin: auto;
  margin-top: 30px;
  h3 {
    margin: 0;
    padding: 19px;
    border-bottom: 1px solid #a5c9ff;
    text-align: start;
    color: #156ab3;
    font-size: 16px;
  }
  &__customStyle {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e2e3e4;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    &:last-child {
      border-radius: 5px;
    }
  }
}

.notificationPageCont {
  height: 75vh;
  overflow-y: scroll;
}
