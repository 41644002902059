/** @format */
@mixin styledImgs($right, $top, $width: fit-content, $spanPostion: 0px) {
  position: absolute;
  border: 1px #2eb9b3 solid;
  box-shadow: -1px 0px 8px #00000029;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  display: flex;
  background-color: #ffffff;
  right: $right;
  top: $top;
  img {
    margin: auto;
    width: 54px;
    height: 49px;
  }
  span {
    position: absolute;
    top: 130px;
    color: #208ab2;
    font-weight: 700;
    right: $spanPostion;
    width: $width;
  }
}
@mixin arrowStyle($right, $top) {
  position: absolute;
  right: $right;
  top: $top;
}
.use-cont {
  margin-top: 96px;
  h3 {
    color: #278db4;
    font-weight: 700;
    font-size: 30px;
    margin: 0px;
  }
  span {
    color: #142535;
    font-weight: 400;
    font-size: 20px;
    margin-top: 12px;
  }
  &__body {
    position: relative;
    &__folder {
      @include styledImgs(15%, 37%);
    }
    &__firstArrow {
      @include arrowStyle(26%, 30%);
    }
    &__time {
      @include styledImgs(35%, 16%, 200px, -37px);
    }
    &__secArrow {
      @include arrowStyle(48%, 19%);
    }
    &__payment {
      @include styledImgs(58%, 16%);
    }
    &__thirdArrow {
      @include arrowStyle(70%, 30%);
    }
    &__delivery {
      @include styledImgs(77%, 37%);
    }
    &__line {
      margin-top: 120px;
      width: 100%;
      height: 381px;
    }
    @media only screen and (max-width: 1150px) {
      display: none;
    }
  }
  &__body-responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    &__cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      &__img-cont {
        @include styledImgs(0%, 0%);
        position: relative !important;
      }
      span {
        color: #208ab2;
        font-weight: 700;
      }
    }
    @media only screen and (min-width: 1151px) {
      display: none;
    }
  }
}

.use-cont[dir="ltr"] {
  .use-cont__body__folder {
    right: unset;
    left: 15%;
    top: 37%;
  }
  .use-cont__body__time {
    right: unset;
    left: 35%;
    top: 16%;
  }
  .use-cont__body__payment {
    right: unset;
    left: 58%;
    top: 16%;
  }
  .use-cont__body__delivery {
    right: unset;
    left: 77%;
    top: 37%;
  }
  .use-cont__body__firstArrow,
  .use-cont__body__secArrow,
  .use-cont__body__thirdArrow {
    transform: rotate(180deg);
  }
}
