/** @format */
@import "../../../Style/common.scss";
.table {
  max-height: 100%;
  overflow: auto;
  &__header {
    text-align: start;
    padding: 0px 12px;
    display: grid;
    height: 37px;
    grid-template-columns: repeat(11, 1fr) 12%;
    border-top: 1px solid #a5c9ff;
    border-bottom: 1px solid #a5c9ff;
    align-items: center;
    &--remove-name {
      grid-template-columns: repeat(10, 1fr) 15%;
    }
    &--translatorTable {
      grid-template-columns: repeat(8, 1fr) 15%;
    }
    &--transactions-table {
      grid-template-columns: repeat(6, 1fr) 15%;
    }
    span {
      @include fontStyle(14px, 700, #174a84);
    }
    &--remove-border {
      border-top: none;
    }
    &--customer-requests {
      grid-template-columns: repeat(9, 1fr) 15%;
    }
    &--translator-requests {
      grid-template-columns: repeat(7, 1fr) 15%;
    }
  }
  a {
    text-decoration: none;
  }
  &__body {
    text-align: start;
    padding: 0px 12px;
    display: grid;
    grid-template-columns: repeat(11, 1fr) 12%;
    border-bottom: 1px solid #a5c9ff;
    align-items: center;
    line-height: 1.4;
    height: 47px;
    &--remove-name {
      grid-template-columns: repeat(10, 1fr) 15%;
    }
    &--translatorTable {
      grid-template-columns: repeat(8, 1fr) 15%;
    }
    &--transactions-table {
      grid-template-columns: repeat(6, 1fr) 15%;
    }
    &--customer-requests {
      grid-template-columns: repeat(9, 1fr) 15%;
    }
    &--translator-requests {
      grid-template-columns: repeat(7, 1fr) 15%;
    }

    span {
      @include fontStyle(12px, 500, #112233);
    }
    &__status {
      display: flex;
      max-width: 150px !important;
      justify-content: space-between;
      span {
        border-radius: 7px;
        width: 118px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--blue {
        span {
          background-color: #d1eefe;
        }
      }
      &--green {
        span {
          background-color: #aaffbe;
        }
      }
      &--yellow {
        span {
          background-color: #ffefca;
        }
      }
      &--red {
        border-radius: 7px;
        span {
          background-color: #ffd9d1;
        }
      }
      &--undefined {
        border-radius: 7px;
        span {
          background-color: #ffd9d1;
        }
      }
      &__flibImg {
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }
    }
    &:hover {
      background-color: #ececec;
    }
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
