/** @format */

@mixin fontStyle($size, $weight, $color: #112233) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
@mixin imgStyle($width, $height) {
  width: $width;
  height: $height;
}
@mixin centerText {
  display: flex;
  align-items: center;
  justify-content: center;
}

button:disabled {
  opacity: 0.4;
}
