/** @format */
@mixin styledBtn($btnWidth, $hight: 64px, $fontSize: 16px, $left: 38%) {
  position: relative;
  width: $btnWidth;
  span {
    position: absolute;
    top: 16%;
    left: $left;
    transform: translateX(-50%);
    color: #1d89b1;
    font-size: $fontSize;
    font-weight: 700;
    white-space: nowrap;
  }
  img {
    height: $hight;
    width: $btnWidth;
  }
}
.section1-cont {
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0px 155px;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    &__coverImg {
      width: 537px;
      height: 530px;
      z-index: 1;
      margin-right: 46px;
      margin-top: 40px;
      @media only screen and (max-width: 620px) {
        width: 300px;
        height: 300px;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
    &__info-cont {
      width: 475px;
      // text-align: right;
      text-align: justify;
      text-align-last: start;
      margin-top: -40px;
      h3 {
        font-size: 26px;
        color: #142535;
        font-weight: 700;
        margin-bottom: 0px;
      }
      span {
        font-weight: 400;
      }
      &__btn-cont {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        &__firstBtn {
          @include styledBtn(257px);
        }
        &__secBtn {
          @include styledBtn(207px);
          margin-right: 10px;
        }
        @media only screen and (max-width: 600px) {
          &__firstBtn {
            @include styledBtn(200px, 56px, 13px, 38%);
          }
          &__secBtn {
            @include styledBtn(150px, 56px, 13px, 34%);
            margin-right: 10px;
          }
        }
      }
    }
    @media only screen and (max-width: 1450px) {
      margin: 0px 1%;
    }
    @media only screen and (max-width: 1075px) {
      margin-top: 20px;
    }
    @media only screen and (max-width: 500px) {
      margin: 10px;
      width: 90%;
    }
  }
}
.background-sec {
  background: linear-gradient(
    180deg,
    #fcfcfc00 0%,
    rgba(229, 229, 229, 1) 100%
  );
  transform: matrix(0.83, 0.56, -0.56, 0.83, 0, 0);
  border-radius: 34px;
  opacity: 1;
  position: absolute;
  width: 50%;
  top: -60px;
  left: -100px;
  height: 100%;
  z-index: -1;
}

.section1-cont[dir="ltr"] {
  .background-sec {
    transform: matrix(0.56, -0.83, 0.83, 0.56, 0, 0);
    left: unset;
    right: 100px;
  }
}
