/** @format */

.navbarAndSidebar {
  background-color: #f7f8fa;

  &__body {
    margin-right: 93px;

    height: calc(100vh - 106px);
    overflow-y: auto;
    &--apply-margin {
      margin-right: 250px;
    }
    &--left {
      margin-right: 0;
      margin-left: 93px;
    }
    &--apply-margin-left {
      margin-left: 250px;
    }
    @media only screen and (max-width: 950px) {
      margin: 0;
    }
  }
}
