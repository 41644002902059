/** @format */
@import "../../../Style/common.scss";
.thired-phase-cont {
  text-align: right;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  span {
    @include fontStyle(14px, 400, #112233);
  }
  h2 {
    @include fontStyle(36px, 700, #156ab3);
    text-align: right;
    margin-top: 0;
    line-height: 1;
  }
  h4 {
    @include fontStyle(18px, 600, #142535);
    margin-bottom: 5px;
  }
  button {
    border: none;
    outline: none;
    border-radius: 5px;
    width: 346px;
    height: 36px;
    margin-top: 19px;
    margin-bottom: 36px;
    @include fontStyle(18px, 600, #ffffff);
    background-image: linear-gradient(#249db3, #30c0b4);
  }
  &__cards-cont {
    display: flex;
    flex-direction: row;
    margin-bottom: 21px;
    &__card {
      // box-shadow: 0px 0px 26px #0000004b;
      // border: 1px solid #d1d1d1;
      background-color: #ffffff;
      margin-left: 10px;
      border-radius: 10px;
      width: auto;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      cursor: pointer;
      &--selected {
        border: 1px solid #156ab3;
        box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.2941176471);
      }
      &__logo {
        width: 110px;
        margin-left: 13px;
      }
      &__radio {
        margin-right: 20px;
        @include imgStyle(29px, 29px);
      }
    }
  }
  &__agreement-checkbox {
    span {
      padding: 0;
      @include fontStyle(14px, 600, #112233);
      a {
        @include fontStyle(14px, 600, #156ab3);
        text-decoration: underline;
      }
    }
  }
}

.text-center {
  text-align: center;
}

.itemPay {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin: 4px;
  width: auto;
}

.m-0 {
  margin: 0;
}
.new-65 {
  padding: 10px 30px !important;
  width: 60% !important;
  margin: 10px 0 !important;
}

@media (max-width: 500px) and (min-width: 320px) {
  .itemPay {
    width: 45% !important;
  }
}

.closeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  width: 27px;
  height: 27px;
}
