/** @format */

.resetPassword-Cont {
  width: 346px;
  margin: auto;
  padding-top: 32px;
  margin-bottom: 46px;
  min-height: 400px;
  max-height: 100vh;
  button {
    background-image: linear-gradient(#249db3, #30c0b4);
    height: 36px;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    margin-top: 11px;
    cursor: pointer;
    &:disabled {
      background-color: #dddddd;
      color: #7f7f7f;
      background-image: none;
      cursor: not-allowed;
    }
  }
}
