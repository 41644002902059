/** @format */
@import "../../../Style/common.scss";
.statistics-cont {
  margin-top: 28px;
  padding: 0px 10px;
  height: 77vh;
  overflow-y: scroll;
  &__statistics {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    &__single-card {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 133px;
      min-width: 22%;
      width: 22%;
      box-shadow: 0px 2px 6px #0000000a;
      background: #ffffff 0% 0% no-repeat padding-box;
      padding: 0px 16px;
      margin-bottom: 14px;
      text-align: right;
      &--customWidth {
        min-width: 22%;
        width: 22%;
      }
      label {
        text-align: start;
        @include fontStyle(15px, 400, #112233);
      }
      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: start;
        span {
          @include fontStyle(30px, 700, #156ab3);
        }
      }
    }
  }

  &__table-cont {
    box-shadow: 0px 3px 16px #0a4b6d14;
    border: 1px solid #a5c9ff;
    border-radius: 7px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 26px;
      h4 {
        @include fontStyle(16px, 700, #156ab3);
      }
      button {
        border: 1px solid #156ab3;
        border-radius: 7px;
        outline: none;
        padding: 0px 16px;
        text-decoration: none;
        @include fontStyle(14px, 600, #156ab3);
        img {
          margin-bottom: -1px;
        }
      }
    }
  }
}
@media (max-width: 500px) and (min-width: 320px) {
  .statistics-cont__table-cont {
    width: 280%;
  }
  .statistics-cont__statistics__single-card {
    min-width: 90%;
    width: 90%;
  }
  .statistics-cont__statistics__single-card--customWidth {
    min-width: 90%;
    width: 90%;
  }
  .statistics-cont__statistics__single-card--customWidth {
    min-width: 90%;
    width: 90%;
  }
}
