/** @format */
@import "../../Style/common.scss";
.translat-cont {
  padding: 0px 150px;
  margin-bottom: 20px;
  height: auto;
  //   text-align: right;
  &__errorMsg {
    margin: auto;
    margin-top: 10px;
    background-color: #f5dad3;
    width: 656px;
    height: 46px;
    border-radius: 7px;

    display: flex;
    align-items: center;
    justify-content: center;
    @include fontStyle(14px, 700, #e30000);
    > img {
      margin: 0px 5px;
    }
    span {
      @include fontStyle(14px, 700, #156ab3);
    }
  }
  h3 {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }
  @media only screen and (max-width: 1317px) {
    padding: 0px 2%;
  }
}
.request-search[dir="ltr"] {
  .input-cont__field-cont img {
    right: 8px;
  }
}
