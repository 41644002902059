/** @format */

.opinions-cont {
  background: url("../../../Assets/Images/Group\ 39833.png") no-repeat
    padding-box center center;
  background-size: cover;
  padding: 70px 155px;
  height: 720px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1;
  h3 {
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 0px;
  }
  &__shape-cont {
    position: relative;
    z-index: 5;
    margin-top: 20px;
    img {
      position: absolute;
      right: 10px;
      top: -15px;
    }
    &__main-div {
      background-color: #353535;
      padding: 0px 32px;
      width: 568px;
      height: 339px;
      border-radius: 20px;
      z-index: 5;
      display: flex;
      align-items: center;
      flex-direction: column;
      h5 {
        color: #fdd348;
        font-size: 25px;
        font-weight: 600;

        margin-bottom: 15px;
        z-index: 5;
      }
      span {
        color: #ffffff;
        font-size: 25px;
        font-weight: 400;
        z-index: 5;
      }
      @media only screen and (max-width: 570px) {
        width: 400px;
        height: 250px;
      }
    }
    &__sec-div {
      position: absolute;
      width: 589px;
      height: 315px;
      border-radius: 20px;
      background-color: #242424 !important;
      top: 13.5%;
      left: 3.8%;
      z-index: -4;
      @media only screen and (max-width: 570px) {
        width: 420px;
        height: 250px;
      }
    }
    &__third-div {
      position: absolute;
      width: 536px;
      height: 287px;
      border-radius: 20px;
      background-color: #131313;
      top: 26.5%;
      left: 8%;
      z-index: -5;
    }
  }
  &__arrows-cont {
    margin-left: 33%;
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
      width: 40px;
      cursor: pointer;
      &:first-child {
        transform: rotate(180deg);
      }
    }
  }
  @media only screen and (max-width: 700px) {
    height: 350px;
    padding: 70px 0%;
    padding-top: 20px;

    &__shape-cont {
      margin-top: 30px;
      width: 100%;

      &__main-div {
        height: 190px;
        width: 79%;
        margin: auto;
        h5 {
          font-size: 18px;
        }
        span {
          font-size: 14px;
        }
      }
      &__sec-div {
        margin: auto;
        width: 86%;
        height: 185px;
        left: 6.8%;
      }
      &__third-div {
        margin: auto;
        width: 78%;
        height: 180px;
        left: 10.5%;
      }
    }
    &__arrows-cont {
      margin-top: 60px;
    }
  }
}

.slider-container-NEW {
  position: relative;
  max-width: 800px; /* Adjust according to your needs */
  margin: auto;
  height: 370px;
  overflow: hidden;
}

.slider-NEW {
  display: flex;
  overflow: hidden;
}

.slide-NEW {
  flex: 1;
  transition: opacity 0.5s ease;
  opacity: 0;
  display: none;
}
@keyframes fadeIn {
  0% {
    transform: translateY(40%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.slide-NEW img {
  width: 100%;
  height: auto;
}

.active-NEW {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease;
  animation: fadeIn 0.5s;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  z-index: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
