/** @format */
@import "../../../Style/common.scss";
.order-details {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  &__info {
    display: flex;
    flex-wrap: wrap;
    &--translator {
      width: 100%;
    }
    &__singleInfo {
      width: 30%;
      height: 60px;
      text-align: start;
      line-height: 1.4;
      &--translator {
        max-width: 212px;
      }
      span {
        display: block;
      }
      &__label {
        @include fontStyle(14, 700, #112233);
      }
      &__data {
        @include fontStyle(14, 400, #112233);
      }
    }
    @media only screen and (max-width: 650px) {
      // flex-direction: column;
      &__singleInfo {
        width: 50%;
      }
    }
  }
}
