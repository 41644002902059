/** @format */

.cutomer-details {
  > h4 {
    text-align: center;
    font-size: 26px;
    color: #156ab3;
    font-weight: 700;
    margin-top: 20px;
  }
  &__bundles {
    padding-top: 20px;
    scroll-padding-inline-end: 10%;
    overflow-y: auto;
    &__cards-container {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      @media only screen and (max-width: 950px) {
        width: 100%;
      }
    }
    &__divider {
      width: 90%;
      @media only screen and (max-width: 950px) {
        width: 100%;
      }
    }
    @media only screen and (max-width: 950px) {
      height: auto;
      padding: 19px;
    }
  }

  &__table-cont {
    margin: 15px 10px;
    box-shadow: 0px 3px 16px #0a4b6d14;
    border: 1px solid #a5c9ff;
    border-radius: 7px;
  }
}
