/** @format */

.sec2-cont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0px 155px;
  margin-top: 160px;
  &__info {
    width: 613px;
    text-align: justify;
    text-align-last: start;
    margin-right: 36px;
    line-height: 1.7;
    margin-right: -28px;
    h3 {
      color: #208ab2;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 0px;
      margin-top: -10px;
    }
    span {
      font-weight: 400;
      display: block;
      margin-bottom: 4px;
      font-size: 19px;
    }
    @media only screen and (max-width: 650px) {
      width: 90%;
      margin: auto;
    }
  }
  &__img-cont {
    position: relative;
    img {
      width: 376px;
      height: 417px;
      margin-right: 28px;
    }
    &__border-div {
      position: absolute;
      top: 10px;
      right: 0;
      width: 252px;
      height: 373px;
      border: #2fbfb4 solid 13px;
      z-index: -1;
    }
    @media only screen and (max-width: 1150px) {
      display: none;
    }
  }
  @media only screen and (max-width: 1450px) {
    margin: 0px 1%;
    margin-top: 60px;
    margin-bottom: 50px;
  }
}
