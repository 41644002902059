/** @format */

.App {
  text-align: center;
  font-family: "Cairo", sans-serif;
  /* display: flex;
  flex-direction: column; */
  /* justify-content: space-between; */
}
.gradient-cont {
  width: 1120px;
  overflow-x: hidden;
  height: 746px;
  background-image: linear-gradient(#fcfcfc, #e5e5e5);
  transform: matrix(0.83, 0.56, -0.56, 0.83, 0, 0);
  border-radius: 34px;
  position: absolute;
  z-index: -1;
  margin-top: -230px;
  margin-left: -327px;
}
@media only screen and (max-width: 1121px) {
  .gradient-cont {
    width: 100%;
  }
}
.pageBody {
  min-height: calc(100vh - 502px);
}
h1,
h3,
h5,
span,
p,
a,
input,
textarea {
  font-family: "Cairo", sans-serif;
}
a {
  text-decoration: none;
}
button {
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Cairo", sans-serif;
  cursor: pointer;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
  appearance: none;
}
@media only screen and (max-width: 600px) {
  h3 {
    margin-top: 20px;
    font-size: 18px !important;
  }
  h4 {
    margin-top: 20px;

    font-size: 18px !important;
  }
}
.btn-back {
  background: #156ab3;
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
}
