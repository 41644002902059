/** @format */

.card-cont {
  //   margin-top: 11px;

  min-width: 307px;
  max-width: 28%;
  height: 345px;
  background-image: url("../../../Assets/Images/Image\ 5.png");
  padding: 19px;
  margin-right: 11px;
  margin-bottom: 11px;
  @media only screen and (max-width: 550px) {
    margin-right: 0px !important;
  }
  &__img-cont {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: #e4f3f6;
    display: flex;
    margin: 0px auto;
    img {
      width: 60px;
      height: 60px;
      margin: auto;
    }
  }
  h5 {
    margin: 0;
    color: #208ab2;
    font-size: 18px;
    font-weight: 700;
    margin-top: 28px;
    margin-bottom: 8px;
  }
  span {
    color: #112233;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7;
  }
}
