/** @format */
@mixin inputCont() {
  border-bottom: 2px solid #cccccc;
  width: 80%;
  min-width: 329px;
  height: 40px;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;

  &:nth-child(2) {
    margin-left: 5%;
  }
  &--full-width {
    width: 100%;
    margin-left: auto;
    margin-top: 65px;
    @media only screen and (max-width:1150px) {
      margin-top: 22px;
    }
  }
  input {
    border: none;
    text-align: right;
    outline: none;
    width: 100%;
  }
  hr {
    margin: 0px;
    border: none;
    border-bottom: 2px solid #cccccc;
  }
  span {
    color: #1d87b2;
    font-size: 16px;
    white-space: nowrap;
  }
}
.contact-us-cont {
  margin: 0px 155px;
  padding-bottom: 75px;
  h3 {
    color: #278db4;
    font-size: 30px;
    font-family: 700;
  }
  span {
    color: #142535;
    font-size: 20px;
    font-family: 400;
  }
  &__first-row {
    margin-top: 65px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    &__input-cont {
      @include inputCont();
      min-width: 388px;
      width: 47.5%;
      @media only screen and (max-width:1150px) {
        width: 100%;
        min-width: 100%;

        &:nth-child(2) {
          margin-left: 0%;
          margin-top: 22px;
        }
      }
    }
  }
  &__input-cont {
    @include inputCont();
  }
  &__textArea-cont {
    margin-top: 65px;
    border-bottom: 2px solid #cccccc;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span {
      color: #1d87b2;
      font-size: 16px;
      white-space: nowrap;
    }
    textarea {
      border: none;
      text-align: right;
      outline: none;
      resize: none;
      width: 100%;
      height: 80px;
    }
    @media only screen and (max-width:1150px) {
      margin-top: 22px;
    }
  }
  button {
    width: 200px;
    height: 43px;
    color: #ffffff;
    font-size: 23px;
    font-weight: 600;
    background-image: linear-gradient(#249db3, #30c0b4);
    border-radius: 5px;
    margin-top: 67px;
  }
  @media only screen and (max-width:850px) {
    margin: 0px 21px;
    h3 {
      font-size: 18px;
    }
    span {
      font-size: 14px;
    }
    &__first-row {
      margin-top: 30px;
    }
  }
}
