/** @format */

.compnay-form-cont {
  text-align: start;
  margin: 100px 355px;
  margin-bottom: 150px;

  h3 {
    font-size: 26px;
    font-weight: 700;
    color: #142535;
    margin: 0;
  }
  > span {
    font-size: 17px;
    font-weight: 400;
    color: #112233;
  }
  &__inputs-cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  button {
    border-radius: 5px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    width: 49.5%;
    background-image: linear-gradient(#249db3, #30c0b4);
    height: 40px;
    float: left;
    margin-top: 12px;
    &:disabled {
      background-color: #dddddd;
      color: #7f7f7f;
      background-image: none;
      cursor: not-allowed;
    }
  }
  @media only screen and (max-width: 1200px) {
    margin: 100px 5%;
  }
}
