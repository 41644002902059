/** @format */
@import "../../Style/common.scss";
@mixin activeLink() {
  color: #27a8b3;
  img {
    display: inline;
    @include imgStyle(17px, 3px);
    position: absolute;
    bottom: 0;
    left: 40%;
    margin-bottom: -5px;
  }
}
@mixin loginBtn() {
  background-image: linear-gradient(#249db3, #30c0b4);
  width: 167px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  @include fontStyle(18px, 600, #ffffff);

  margin-right: 24px;
  cursor: pointer;
}

.navbar-container {
  margin: 10px 155px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__loginAndlang {
    display: flex;
  }
  button {
    @include loginBtn();
  }
  span {
    @include fontStyle(16px, 400);

    cursor: pointer;
    img {
      @include imgStyle(22px, 13px);
    }
  }
  &__link {
    position: relative;

    @include fontStyle(16px, 600);

    margin-left: 20px;
    cursor: pointer;
    img {
      display: none;
    }
    &:first-of-type {
      @include activeLink();
    }
    &:hover {
      @include activeLink();
    }
  }

  &__logo {
    @include imgStyle(168px, 83px);
  }
  @media only screen and (max-width: 1160px) {
    margin: 10px 2%;
  }
  @media only screen and (max-width: 881px) {
    display: none;
  }
}
.navbar-container-mobile {
  height: 90px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 25px;
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0px;

    &__logo {
      @include imgStyle(120px, 60px);
    }
    img {
      cursor: pointer;
    }
    &--customeStyle {
      position: absolute;
      width: 100%;
      top: 0;
      z-index: 11;
      background-color: white;
      box-shadow: 0px 3px 6px #00000029;
    }
    span {
      font-family: "Cairo", sans-serif;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
      img {
        @include imgStyle(22px, 13px);
      }
    }
  }
  .MuiDrawer-root {
    z-index: 10 !important;
  }
  &__drawer-cont {
    width: 309px;
    padding: 19px;
    padding-top: 120px;
    text-align: right;
    button {
      @include loginBtn();
      margin-right: 0px;
    }
    &__row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 13px;
      // margin-top: 26px;
      cursor: pointer;
      img {
        margin-left: 25px;
      }
      &:hover {
        background-color: #ececec;
      }
    }
  }
  @media only screen and (min-width: 880px) {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 320px) {
  .navbar-container-mobile__body {
    padding: 7px 0px !important;
  }
  .navbar-container-mobile {
    position: relative;
    z-index: 99999999 !important;
    height: 66px;
    margin-bottom: 25px;
  }
  .navbar-container-mobile__body__logo {
    width: 100px;
    height: auto;
  }
  .navbar-container-mobile__drawer-cont {
    padding-top: 15px;
  }
}
