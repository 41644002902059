/** @format */
@mixin modalStyle() {
  padding: 20px 75px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 524px;
  box-shadow: 0px 0px 26px #0000004b;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  background-color: white !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  outline: none;
  .logo {
    width: 168px;
    height: 83px;
    margin: 0px auto;
  }
  .closeIcon {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    width: 27px;
    height: 27px;
  }
  h5 {
    color: #142535;
    font-size: 18px;
    font-weight: 700;
    margin: 8px auto;
  }
  @media only screen and (max-width: 550px) {
    width: 90%;
    padding: 20px 10px !important;
  }
}
.login-cont {
  @include modalStyle();

  height: fit-content;

  &__checkbox-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 13px;
    span {
      padding: 0px !important;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
    &__forget-pass {
      color: #156ab3;
      text-decoration: underline;
    }
  }
  button {
    background-image: linear-gradient(#249db3, #30c0b4);
    height: 36px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    margin-top: 11px;
    cursor: pointer;
    &:disabled {
      background-color: #dddddd;
      color: #7f7f7f;
      background-image: none;
      cursor: not-allowed;
    }
  }
  &__signin-cont {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    span {
      padding: 0px !important;
      font-size: 14px;
      font-weight: 600;
    }
    &__signin {
      cursor: pointer;
      color: #156ab3;
      text-decoration: underline;
      margin-right: 5px;
    }
  }
  &__cancelModal-footer {
    display: flex;
    justify-content: space-between;

    button {
      width: 173px;
      margin-top: 0;
      height: 40px;
      &:first-child {
        background-image: none;
        background-color: #7d7d7d;
      }
    }
  }
}
