/** @format */
.footer {
  .footer-cont {
    background-image: url("../../Assets/Images/Group\ 39818.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 56px 155px;
    display: flex;
    justify-content: space-around;
    text-align: start;
    color: #ffffff;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    h5 {
      font-size: 20px;
      font-weight: 700;
      color: #ffffff;
      margin: 0;
      margin-bottom: 20px;
    }
    div {
      min-width: 257px;
      // max-width: 457px;
    }
    span {
      display: block;
    }
    a {
      display: block;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 5px;
      cursor: pointer;
      color: white !important;
    }
    &__sect-cont {
      width: 28%;
      &__info {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        margin-bottom: 10px;
        img {
          margin: 0px 10px;
          width: 19px;
          height: 15px;
        }
      }
    }
    &__links {
      width: 28%;
      margin-bottom: 20px;
      text-align: start;
    }
    &__about-us {
      min-width: 457px;
      width: 40%;
      text-align: justify;
      text-align-last: start;
      margin-left: 10px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 1150px) {
      padding: 56px 3%;
    }
  }

  .socialMediaImg {
    width: 19px;
    height: 15px;
    margin-right: 16px;
  }
}
.copyright {
  // position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #112233;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  span {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
}
