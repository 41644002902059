/** @format */
@import "../../../Style/common.scss";
.customers-cont {
  background-color: #f7f8fa;

  overflow-y: auto;
  &__body {
    box-shadow: 0px 3px 16px #0a4b6d14;
    border: 1px solid #a5c9ff;
    border-radius: 7px;
    margin: 20px 10px;

    background-color: #ffffff;

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 24px;
      h4 {
        @include fontStyle(16px, 700, #156ab3);
      }
      &__langBtns {
        display: flex;
      }
      button {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: fit-content;
        min-width: 127px;
        height: 32px;
        border: 1px solid #156ab3;
        border-radius: 7px;
        @include fontStyle(14px, 600, #156ab3);
        &:hover {
          color: #ffffff !important;
          background-image: linear-gradient(#249db3, #30c0b4);
          border: none;
        }
      }
    }
  }
}
