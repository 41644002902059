/** @format */

.seconed-phase {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  &__inputFields {
    width: 70%;
    &__primary-fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      &__address-checkbox {
        display: flex;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
        span {
          padding: 0;
          margin-left: 5px;
        }
      }

      @media only screen and (max-width: 750px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &__address-firstRow {
      display: grid;
      grid-template-columns: 25% 75%;
      gap: 10px;
    }
    &__address-seconedRow {
      display: grid;
      grid-template-columns: repeat(3, 25%);
      gap: 10px;
    }

    button {
      margin: 20px 0;
      width: 346px;
      height: 36px;
      color: #ffffff;
      border-radius: 5px;
      background-image: linear-gradient(#249db3, #30c0b4);
      font-size: 18px;
      font-weight: 600;
      &:disabled {
        background-color: #dddddd;
        color: #7f7f7f;
        background-image: none;
        cursor: not-allowed;
      }
      // float: right;
    }
    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;

      &__address-firstRow {
        grid-template-columns: 1fr;
      }
      &__address-seconedRow {
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 1570px) {
    justify-content: center;
  }
  @media only screen and (max-width: 1050px) {
    justify-content: center;
    &__inputFields {
      width: 100%;
    }
  }
}
@media (max-width: 500px) and (min-width: 320px) {
  .seconed-phase__inputFields button {
    width: 100%;
  }
}
