/** @format */
@import "../../../Style/common.scss";
.progress {
  // border-bottom: 6px solid #dddddd;
  width: 522px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;
  &__bar {
    height: 6px;
    width: 90%;
    background-color: #dddddd;
    position: absolute;
    top: 27px;
    right: 20px;
  }
  &__point {
    position: relative;
    display: flex;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    &__mainImg {
      margin-top: 12px;
      @include imgStyle(33px, 33px);
    }
    &__checked {
      position: absolute;
      left: 14px;
      top: 23px;
      @include imgStyle(20px, 16px);
      cursor: pointer;
    }
    &__innerPoints {
      position: absolute;
      left: 8px;
      top: 27px;
      &--extraStyle {
        left: 17px !important;
      }
    }
    &__msg {
      // left: -24px !important;
    }
    span {
      position: absolute;
      // left: -15px;
      top: 52px;
      white-space: nowrap;
      @include fontStyle(17px, 600);
    }
  }
  @media only screen and (max-width: 600px) {
    width: 85%;
  }
}
