/** @format */

.translator-cont {
  background-image: url("../../../Assets/Images/Group 39817.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -10px;
  padding: 38px 155px;
  h3 {
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 0px;
  }
  &__cards-cont {
    display: flex;
    margin-top: 90px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    &__card {
      background-color: #ffffff;

      min-width: 348px;
      width: 28%;
      margin-right: 10px;
      min-height: 198px;
      box-shadow: -1px 0px 8px #00000029;
      border-radius: 10px;
      position: relative;
      text-align: center;
      display: flex;
      align-items: flex-start;
      //   padding-top: 50px;
      &__img-cont {
        width: 104px;
        height: 104px;
        border: 5px solid #328996;
        border-radius: 100%;
        display: flex;
        position: absolute;
        background-color: #ffffff;
        top: -60px;
        left: 33%;
        img {
          margin: auto;
        }
        img:nth-child(5) {
          width: 130px !important;
          height: 68px;
        }
      }
      span {
        color: #112233;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 76px;
      }
      &__number {
        position: absolute;
        top: -58px;
        right: 6px;
        color: #208ab2;
        font-size: 40px;
        font-weight: 700;
        opacity: 0.14;
      }
      @media only screen and (max-width: 1120px) {
        margin-bottom: 73px;
      }
      @media only screen and (max-width: 520px) {
        width: 348px !important;
      }
    }
    &--phoneWindow {
      margin-top: 0;
      @media only screen and (min-width: 520px) {
        display: none;
      }
    }
    &--secRow {
      min-width: 517px;
      .translator-cont__cards-cont__card {
        padding: 0px 20px;
        &__img-cont {
          left: 40%;
        }
      }
      @media only screen and (max-width: 815px) {
        margin-top: 0px;
      }
      @media only screen and (max-width: 520px) {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 1450px) {
    padding: 38px 1%;
  }
}
