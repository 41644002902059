/** @format */
@import "../../../Style/common.scss";
.resposiveRow-container {
  padding: 0px 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-height: calc(100vh - 366px);
  overflow: auto;
  &__row {
    width: 300px;
    height: 188px;
    background-color: white;
    border-radius: 5px;
    padding: 12px 24px;
    margin-bottom: 20px;
    &__data-row {
      display: flex;
      justify-content: space-between;
      &__titleAndValue {
        text-align: start;
        width: fit-content;
        span {
          display: block;
        }
        &__title {
          @include fontStyle(18px, 700, #333333);
        }
        &__value {
          @include fontStyle(16px, 500, #777777);
        }
      }
      &__status {
        @include centerText();
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    display: none;
  }
}
