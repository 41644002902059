/** @format */
@import "../../../../../Style/common.scss";
.lang-header-title {
  @include fontStyle(26px, 700, #156ab3);
}
.Lang-price {
  max-height: calc(100vh - 295px);
  overflow-y: auto;
  overflow-x: hidden;
  &__body {
    max-width: 880px;
    width: 100%;
    margin: auto;
    text-align: start;
    button {
      background-image: linear-gradient(#249db3, #30c0b4);
      width: 323px;
      height: 40px;
      border-radius: 5px;
      margin-top: 20px;
      border: none;
      outline: none;
      @include fontStyle(18px, 600, #ffffff);
      &:disabled {
        background-color: #dddddd;
        color: #7f7f7f;
        background-image: none;
        cursor: not-allowed;
      }
    }
    &__select-cont {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 28px;
      flex-wrap: wrap;
      div {
        width: 434px;
      }
    }
    &__priceForm {
      display: flex;
      align-items: center;
      margin: 0px -5px 10px -5px;
      .input-cont {
        margin: 0px 5px;
        max-width: 212px;
        width: 100%;
      }
      > img {
        margin: 25px 10px 0px 10px;
        cursor: pointer;
      }
    }
  }
}
