/** @format */
@import "../../../Style/common.scss";
.customNav-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e3e4;

  &__options {
    height: 66px;
    padding: 19px 17px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    &__logo {
      width: 112px;
      height: 56px;
      margin-top: 10px;
      @media only screen and (min-width: 950px) {
        display: none;
      }
    }
    &__adminDrobdown {
      cursor: pointer !important;
      display: flex;
      align-items: center;
      @include fontStyle(16px, 600, #112233);
      &__arrow {
        margin-bottom: -5px;
        margin-right: 4px;
        cursor: pointer !important;
      }
      &__logo {
        margin-left: 10px;
        @include imgStyle(32px, 26px);
      }
      @media only screen and (max-width: 950px) {
        display: none;
      }
    }
    &__lang {
      margin: 0px 25px;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        margin-left: 7px;
        @include imgStyle(22px, 13px);
      }
    }
    &__notification {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      img {
        @include imgStyle(32px, 32px);
      }
      &__num {
        display: flex;
        align-items: center;
        justify-content: center;
        top: -2px;
        right: -11px;
        width: 27px;

        height: 15px;
        border-radius: 26px;
        position: absolute;
        background-color: #ff8181;
        @include fontStyle(14px, 600, #ffffff);
        text-align: center;
      }
    }
  }
  &__reminder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      padding: 3px 6px;
      background-color: #156ab3;
      border-radius: 0px 7px 7px 0px;
      @include fontStyle(14px, 600, #ffffff);
    }
    &__count {
      padding: 3px 6px;
      margin: 0px 3px;
      background-color: #94ded7;
      border-radius: 7px 0px 0px 7px;
      @include fontStyle(14px, 600, #174a84);
    }
    &__buy-bundle {
      cursor: pointer;
      margin: 0px 8px;
      @include fontStyle(12px, 600, #156ab3);
      text-decoration: underline;
      display: flex;
      align-items: center;
    }
    @media only screen and (max-width: 950px) {
      display: none;
    }
  }
  &__name {
    @include fontStyle(14px, 400, #112233);
    &__en {
      margin-left: 93px;
      &--apply-margin {
        margin-left: 250px;
      }
    }
    &__ar {
      margin-right: 93px;
      &--apply-margin {
        margin-right: 250px;
      }
    }
    @media only screen and (max-width: 950px) {
      &__ar {
        margin: 0;
        display: none;
      }
      &__en {
        display: none;
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: 950px) {
    box-shadow: 0px 3px 6px #00000029;
    justify-content: space-around;
  }
  @media only screen and (max-width: 400px) {
    justify-content: flex-start !important;
  }
}
@media (max-width: 500px) and (min-width: 320px) {
  .customNav-cont {
    z-index: 99999999 !important;
    background: #fff !important;
    position: relative;
  }
  h4 {
    margin-top: 16px;
    font-size: 14px !important;
  }
  .Drawer-burger-menu {
    position: absolute !important;
    z-index: 9999999999999999999 !important;
  }

  .notifications-cont {
    right: 0 !important;
    top: 48px !important;
    left: unset !important;
  }
  .customNav-cont[dir="rtl"] {
    .notifications-cont {
      right: unset !important;
      top: 48px !important;
      left: 0 !important;
    }
  }
  .thired-phase-cont {
    flex-direction: column;
    align-items: center;
  }
}
.Drawer-burger-menu.ltr {
  left: 18px;
  right: unset;
}

.customNav-cont[dir="rtl"] {
  .customNav-cont__reminder__title {
    border-radius: 7px 0px 0px 7px;
  }
  .customNav-cont__reminder__count {
    border-radius: 0 7px 7px 0;
  }
}
