/** @format */

@mixin styledBtn(
  $btnWidth,
  $hight: 64px,
  $fontSize: 16px,
  $left: 39%,
  $top: 19%
) {
  position: relative;
  width: $btnWidth;
  span {
    position: absolute;
    top: $top;
    left: $left;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: $fontSize;
    font-weight: 700;
    white-space: nowrap;
  }
  img {
    height: $hight;
    width: $btnWidth;
  }
}
.offerSec2-cont {
  margin-top: 64px;
  background-image: url("../../../Assets/Images/Group 39817.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 155px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: start;
  color: #ffffff;
  &__flags-cont {
    img {
      margin-top: 81px;
      width: 461px;
      height: 405px;
      @media only screen and (max-width: 550px) {
        width: 350px;
        height: 350px;
      }
    }
  }
  &__info {
    width: 523px;
    text-align: justify;
    text-align-last: start;
    h3 {
      font-size: 30px;
      font-weight: 700;
      margin: 0;
    }
    h4 {
      margin-bottom: 0;
      font-size: 26px;
      font-weight: 700;
    }
    span {
      font-size: 17px;
      font-weight: 00;
      line-height: 1.5;
      display: block;
      img {
        width: 20px;
        height: 17px;
        margin-left: 5px;
        margin-bottom: -4px;
      }
    }
    &__fields {
      display: flex;
      text-align: start;
      flex-wrap: wrap;
      flex-direction: row;
      span {
        width: 50%;
        min-width: 260px;
      }
      @media only screen and (max-width: 550px) {
        justify-content: normal;
      }
    }
    &__btn-cont {
      margin-top: 30px;
      &__firstBtn {
        @include styledBtn(257px);
      }
      &__secBtn {
        @include styledBtn(207px);
        margin-right: 10px;
      }
      @media only screen and (max-width: 600px) {
        &__firstBtn {
          @include styledBtn(200px, 56px, 13px, 38%, 21%);
        }
        &__secBtn {
          @include styledBtn(150px, 56px, 13px, 34%, 21%);
          margin-right: 10px;
        }
      }
    }
    @media only screen and (max-width: 550px) {
      width: 90%;
    }
  }
  @media only screen and (max-width: 1450px) {
    padding: 70px 1%;
  }
}
