/** @format */
@import "../../../Style/common.scss";
.pagination-cont {
  position: absolute;
  bottom: 40px;
  left: 45%;
  display: flex;
  align-items: center;
  span {
    @include fontStyle(12px, 400, #c7c8c9);
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #ffffff;
    margin: 4px;
    cursor: pointer;
  }
  &__selected {
    box-shadow: 0px 3px 6px #0a4b6d14;
    border: 1px solid #156ab3;
    width: 32px;
    height: 32px;

    @include fontStyle(14px, 400, #156ab3);
  }
  &__notSelected {
    width: 24px;
    height: 24px;
    box-shadow: 0px 3px 6px #0a4b6d14;
    @include fontStyle(14px, 400, #c7c8c9);
  }
}
