/** @format */

.offerSec1-cont {
  margin: 0px 155px;
  margin-top: 77px;
  display: flex;
  justify-content: center;
  &__cards-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media only screen and (max-width: 1500px) {
    margin: 0px 1%;
  }
}
