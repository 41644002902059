/** @format */
@mixin undirLineSpanStyle() {
  cursor: pointer;
  color: #156ab3;
  text-decoration: underline;
}
@import "../../../Style/common.scss";
@import "../LoginModal/index.scss";
.signup-cont {
  @include modalStyle();
  width: 524px;
  overflow-y: auto;
  min-height: fit-content;
  max-height: 100%;
  &--decrease-padding {
    padding: 20px !important;
  }
  button {
    background-image: linear-gradient(#249db3, #30c0b4);
    height: 36px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    margin-top: 11px;
    cursor: pointer;
  }
  &__signin-cont {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    span {
      padding: 0px !important;
      font-size: 14px;
      font-weight: 600;
    }

    &__signin {
      @include undirLineSpanStyle();
      margin-right: 5px;
    }
  }
  &__forget-cont {
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    &--resendEmail {
      margin-top: 40px;
      &__reSend {
        font-size: 14px;
        font-weight: 600;
        @include undirLineSpanStyle();
      }
      &__reSendMsg {
        @include fontStyle(14px, 700, #112233);
      }
    }
    span {
      font-size: 14px;
      font-weight: 600;
      @include undirLineSpanStyle();
    }
  }
}
