/** @format */
@import "../../../Style/common.scss";
.notifications-cont {
  position: absolute;
  left: -96px;
  top: 48px;
  display: none;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 12000;
  background-color: white;
  &--show {
    display: block;
    min-width: 170px;
  }
  &__notification {
    width: 264px;
    height: 65px;
    background-color: #ffff000d;
    border: 1px solid #e2e3e4;
    border-bottom: none;
    display: flex;
    flex-direction: row-reverse;

    align-items: center;
    justify-content: flex-start;
    &--seen {
      background-color: #ffffff;
    }
    img {
      margin: 21px 13px;
      @include imgStyle(24px, 24px);
    }
    &__info {
      line-height: 1.1;
      &__msg {
        @include fontStyle(14px, 400, #112233);
      }
      &__date {
        span {
          @include fontStyle(10px, 400, #7d7d7d);
          margin-left: 10px;
        }
      }
    }
    &:hover {
      background-color: #ececec;
    }
  }
  button {
    background-color: #156ab3;
    border: 1px solid #e2e3e4;
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    height: 40px;
    @include fontStyle(14px, 600, #ffffff);
  }
}
