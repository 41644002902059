/** @format */
@import "../../../Style/common.scss";
.customer-table {
  text-align: start;
  max-height: calc(100vh - 295px);
  overflow-y: auto;
  overflow-x: hidden;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    padding: 0px 33px;
    border-top: 1px solid #a5c9ff;
    span {
      &:last-child {
        min-width: 100px;
      }
      min-width: 220px;
      @include fontStyle(14px, 700, #174a84);
    }
    &__constants {
      display: flex;
    }
  }
  a {
    text-decoration: none;
  }
  &__body {
    border-top: 1px solid #a5c9ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 33px;
    height: 43px;
    &__info {
      display: flex;
      span {
        width: 220px;
        @include fontStyle(12px, 600, #112233);
      }
    }
    &__settings {
      width: 100px;
      display: flex;
      justify-content: space-between;
      img {
        &:first-child {
          @include imgStyle(19px, 17px);
        }
        cursor: pointer;
        object-fit: contain;
        @include imgStyle(15px, 17px);
      }
    }
    &:hover {
      background-color: #ececec;
    }
  }
}
